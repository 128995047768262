@media (max-width: 920px) {
    h1 {
        font-size: 10rem;
    }
    .single-photo {
        margin: 25px 10px 0px 10px;
        
    }
    .grid-figure {
        max-width: calc(50% - 4rem);
        flex-basis: calc(50% - 4rem);
    }
}

@media (max-width: 700px){
    .footer {
        margin: 30px 10px 10px 10px;
    }
    footer {
        flex-wrap: wrap;
    }
    footer .italics {
        margin: 5px auto;
    }
    h1 {
        font-size: 8rem;
    }
    .single-photo {
        flex-wrap: wrap;
        justify-content: center;
        max-width: 100%;
    }
    .grid-figure {
        max-width: calc(50% - 4rem);
        flex-basis: calc(50% - 4rem);
    }
    .single-photo .grid-figure, .single-photo .comments {
        max-width: 100%;
    }
}

@media (max-width: 550px) {
    h1 {
        font-size: 5rem;
    }
    .grid-figure {
        max-width: calc(100% - 4rem);
        flex-basis: calc(100% - 4rem);
    }
}