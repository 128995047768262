button.AddPic {
    display: block;
    width: 200px;
    margin: 10px auto;

}

button.AddPic:disabled {
    background-color: #C7C6C6;
    cursor: not-allowed;
    border: 1px solid #ccc;
    color: #888888;
}

.preview {
    display: flex;

}
.preview>* {
    margin-top: 20px;
    margin-bottom: 20px;
}
.preview>div {
    margin-left: 20px;
}
.preview p {
    text-align: left;
}
.picPreview {
    /* width: auto; */
    /* max-height: 300px; */
}

.previewDiv {
    width: 250px;
    height: 250px;
    overflow: hidden;
}
.description {
    outline: none;
    resize: none;
    overflow: auto;
    width: 100%;
}