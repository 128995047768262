button.Login {
    display: block;
    width: 200px;
    margin: 10px auto;

}

button.Login:disabled {
    background-color: #C7C6C6;
    cursor: not-allowed;
    border: 1px solid #ccc;
    color: #888888;
}

p.Subnote {
    color: #125688;
    font-size: 1.2rem;
}

span.signup {
    font-weight: bold;
    cursor: pointer
}